require("../modules/front/form_parameters");
require("../modules/front/make_city_list");
require("../modules/front/home/modal_banner");

// 検索トップ
$(function () {
  // 「条件から探す」際、未選択ならlocation.hrefで遷移
  $(".js--form-submit").on("click", (e) => {
    let selector = false;
    $("input[type=checkbox]").each(function (_i, el) {
      selector = selector || el.checked;
    });
    if ($(".js-prefecture-select")[0].selectedIndex != 0 || $(".js-city-select")[0].selectedIndex != 0 || selector) {
      $(".js--side-form").submit();
    } else {
      location.href = "/offices";
    }
  });
});
